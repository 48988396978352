import { useAppInitData, useCandidateAuth } from 'candidate/utils';
import { BlankScreenLoading } from 'global/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { PageLayout } from '../../PageLayout';
import { PageNotFound } from './PageNotFound';

export function PrivatePageNotFound() {
  const auth = useCandidateAuth();

  const { candidateAvailability, isLoading: isLoading_candidateInitData } = useAppInitData({
    userID: auth.user?.id,
  });

  if (isLoading_candidateInitData) {
    return <BlankScreenLoading />;
  }

  return (
    <PageLayout.Variation.WithAllSections
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      userFullName={auth.userFullName}
      candidateAvailability={candidateAvailability}
    >
      <PageNotFound />
    </PageLayout.Variation.WithAllSections>
  );
}
