import type { ComponentProps, MouseEventHandler, Reducer } from 'react';
import { useEffect, useState, useReducer } from 'react';
import { useToast } from '@terminal/design-system';
import {
  cleanFilename,
  useQueryString,
  useRedirection,
  asyncReducer,
  toAsyncProps,
  createAsyncInitialState,
} from 'global/utils';
import { v4 as uuidv4 } from 'uuid';
import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  InputMaybe,
  Scalars,
} from 'global/types/hasura-tables.generated.types';
import {
  Candidate_Availability_Choices_Enum,
  Candidate_Source_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useParams, useRouteMatch } from 'react-router-dom';
import { firebaseAuth, createStorageRef, uploadBytes } from 'global/firebaseApp';
import { useAuthContinueUrl, useSignUp, signOut } from 'global/auth';
import { appState, useCandidateAuth } from 'candidate/utils';
import { CustomRoute, SwitchWithPageNotFound } from 'candidate/components';
import { from, of, interval, defer } from 'rxjs';
import { map, catchError, retry, take } from 'rxjs/operators';
import type { RetryConfig } from 'rxjs';
import { track, name, identify } from 'global/events';
import { Section } from '../../DirectApplication.types';
import { ApplicationSections } from './ApplicationSections';
import type { PersonalInfoForm } from './PersonalInfo.controller';
import { NoAccountCreatedSuccessController } from '../NoAccountCreatedSuccess';
import { AccountCreatedSuccessController } from '../AccountCreatedSuccess';
import type { ResumeAndExperienceForm } from './ResumeAndExperience.controller';
import type { GetMatchedToMoreRolesForm } from './GetMatchedToMoreRoles.controller';
import { UpdateCandidate, InsertCandidate, InsertCandidateParsedResume } from '../../graphql';
import type {
  InsertCandidateMutation,
  InsertCandidateMutationVariables,
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables,
  InsertCandidateParsedResumeMutation,
  InsertCandidateParsedResumeMutationVariables,
} from '../../graphql';

const camelToSnakeCase = (str?: String) =>
  str?.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

function retryPromise<T>(promiseFactory: () => Promise<T>, retryConfig: RetryConfig): Promise<T> {
  return new Promise((resolve, reject) => {
    defer(() => from(promiseFactory()))
      .pipe(
        map((result) => [result]),
        retry(retryConfig),
        catchError((error) => of([null, error])),
      )
      .subscribe(([result, error]) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(result as T);
      });
  });
}

export function ApplicationSectionsController({
  companyName,
  jobLocation,
  onAccountExistsClick,
  position,
  skillsOptions,
  handleRecognizedApplicant,
  handleCandidateExists,
  yearsOfExperienceRangeChoices,
  handleNoCountrySupported,
}: Omit<
  ComponentProps<typeof ApplicationSections>,
  | 'onSectionBoxEditClick'
  | 'activeSection'
  | 'personalInfoOnComplete'
  | 'resumeAndExperienceOnComplete'
  | 'getMatchedToMoreRolesOnComplete'
  | 'sectionSubmitButtonIsLoading'
  | 'completedSections'
> & {
  handleRecognizedApplicant: (values: PersonalInfoForm) => void;
  handleCandidateExists: (values: PersonalInfoForm) => void;
  handleNoCountrySupported: (values: PersonalInfoForm) => void;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<Section>(Section.personalInformation);
  const [completedSections, setCompletedSections] = useState<Record<Section, boolean>>({
    [Section.personalInformation]: false,
    [Section.experience]: false,
    [Section.accountCreation]: false,
  });
  const redirectTo = useRedirection();
  const continueURL = useAuthContinueUrl();
  const auth = useCandidateAuth();
  const { jobID } = useParams<{ jobID: string }>();

  const { path, url } = useRouteMatch();
  const queryString = useQueryString();

  const { handleSignUpSubmit } = useSignUp({
    continueURL,
  });

  const completeSection = ({
    section,
    email_recognized = false,
    create_account_toggle = false,
  }: {
    section: Section;
    email_recognized?: boolean;
    create_account_toggle?: boolean;
  }) => {
    track(name.directApplication.sectionCompleted, {
      section_name: camelToSnakeCase(Section[section]),
      step_num: section + 1,
      step_total: 3,
      email_recognized,
      create_account_toggle,
    });

    setCompletedSections({ ...completedSections, [section]: true });
  };

  const [formValues, setFormValues] = useState<{
    personalInfo?: PersonalInfoForm;
    resumeAndExperience?: ResumeAndExperienceForm;
    getMatchedToMoreRoles?: GetMatchedToMoreRolesForm;
  }>({});

  const [updateCandidate, { loading: isUpdatingLoading }] = useMutation<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >(UpdateCandidate, {
    onCompleted: () => {
      toast({
        status: 'success',
        description: 'Your submission was successfully created',
      });

      track(name.directApplication.formSubmitted, {
        job_id: jobID,
      });

      if (formValues?.getMatchedToMoreRoles?.getMatchedToMoreRoles) {
        redirectTo(`${url}/account-created-success`);
        setIsCreatingAccount(false);
      } else {
        redirectTo(`${url}/no-account-created-success}`);
      }

      // After the submission was made it is safe to turn this off so the AppRouter can display the ActivityIndicator without affecting the logic of this component
      appState.flow.update('is-signing-up-in-direct-application', false);
    },
  });

  const [insertCandidate, { loading: isInsertingLoading }] = useMutation<
    InsertCandidateMutation,
    InsertCandidateMutationVariables
  >(InsertCandidate, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to save your personal info. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    onCompleted: () => {
      toast({
        status: 'success',
        description: 'Your submission was successfully created',
      });

      track(name.directApplication.formSubmitted, {
        job_id: jobID,
      });

      if (formValues?.getMatchedToMoreRoles?.getMatchedToMoreRoles) {
        redirectTo(`${url}/account-created-success`);
      } else {
        redirectTo(`${url}/no-account-created-success`);
      }
    },
  });

  const [insert_candidate_parsed_resume] = useMutation<
    InsertCandidateParsedResumeMutation,
    InsertCandidateParsedResumeMutationVariables
  >(InsertCandidateParsedResume, {});

  const [sectionSubmitButtonIsLoading, setSectionSubmitButtonIsLoading] = useState(false);

  const onSectionBoxEditClick: (section: Section) => MouseEventHandler<HTMLButtonElement> =
    (section: Section) => () => {
      if (section !== activeSection && completedSections[section]) {
        setActiveSection(section);
      }
    };

  const [state, dispatchAccountCreation] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );
  const { isLoading: isLoading_accountCreation, isIdle: isIdle_accountCreation } =
    toAsyncProps(state);

  const personalInfoOnComplete = async (values: PersonalInfoForm) => {
    try {
      setSectionSubmitButtonIsLoading(true);

      const checkEmailResult: {
        candidate_exists: boolean;
        user_exists: boolean;
      } = await fetch(`${import.meta.env.REACT_APP_AUTH_URL}/check-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: values.email }),
      }).then((res) => res.json());

      setSectionSubmitButtonIsLoading(false);

      track(name.directApplication.sectionCompleted, {
        section_name: camelToSnakeCase(Section[activeSection]),
        step_num: activeSection + 1,
        step_total: 3,
        email_recognized: checkEmailResult.candidate_exists,
      });

      if (checkEmailResult.candidate_exists) {
        if (!checkEmailResult.user_exists) {
          handleRecognizedApplicant(values);
        } else {
          handleCandidateExists(values);
        }

        completeSection({
          section: Section.personalInformation,
          email_recognized: checkEmailResult.candidate_exists,
          create_account_toggle: false,
        });

        return;
      }

      /**
       * This needs to be evaluated at last. That way if the account already
       * exists the user is redirected to the proper flow instead of to the wait list.
       */
      if (values.location === 'Other') {
        handleNoCountrySupported(values);
        return;
      }

      setFormValues({ ...formValues, personalInfo: values });

      // Open next section
      completeSection({
        section: Section.personalInformation,
        email_recognized: checkEmailResult.candidate_exists,
        create_account_toggle: false,
      });
      setActiveSection(activeSection + 1);
    } catch (error: unknown) {
      // Open next section
      completeSection({ section: Section.personalInformation });
      setActiveSection(activeSection + 1);
      /*
          If it fails to check for current users it should open next section.
          TODO: handle if there is a conflict when inserting the candidate. dismiss new data to avoid over writting it
        */
    } finally {
      // We need to send null here because we don't have user ID yet
      identify(null, {
        user: { email: values.email, user_roles: [] },
        candidate: { first_name: values.firstName, last_name: values.lastName },
      });
    }
  };

  const resumeAndExperienceOnComplete = async (values: ResumeAndExperienceForm) => {
    setSectionSubmitButtonIsLoading(true);

    setFormValues({ ...formValues, resumeAndExperience: values });
    setSectionSubmitButtonIsLoading(false);

    // Open next section
    completeSection({ section: Section.experience });
    setActiveSection(activeSection + 1);
  };

  const getMatchedToMoreRolesOnComplete = async (
    getMatchedToMoreRoles: GetMatchedToMoreRolesForm,
  ) => {
    const publicID = uuidv4();

    setSectionSubmitButtonIsLoading(true);
    setFormValues({ ...formValues, getMatchedToMoreRoles });

    try {
      // We need to set this appState value to avoid the AppRouter from unmounting the whole component thus losing the state
      appState.flow.update('is-signing-up-in-direct-application', true);

      if (getMatchedToMoreRoles.getMatchedToMoreRoles) {
        // Create Account
        setIsCreatingAccount(true);
        await handleSignUpSubmit({
          // We know we always going to have formValues.personalInfo when code reaches here
          email: formValues?.personalInfo?.email as string,
          password: getMatchedToMoreRoles.password,
          firstName: formValues?.personalInfo?.firstName as string,
          lastName: formValues?.personalInfo?.lastName as string,
        });
      } else {
        if (firebaseAuth.currentUser?.isAnonymous) {
          await signOut({ auth: firebaseAuth });
        }

        const candidate_skills = formValues?.resumeAndExperience?.candidateSkills.map(
          (newSkillValues) => ({
            competency: newSkillValues.competency,
            skill_id: newSkillValues.skillID,
            years_of_exp: newSkillValues.years_of_exp as InputMaybe<Scalars['Int']>,
            is_favorite: newSkillValues.is_favorite,
            source: Candidate_Source_Choices_Enum.Applicant,
          }),
        );

        const isExternalReferrer =
          document.referrer.length && new URL(document.referrer).host !== window.location.host;

        const newFilename = formValues?.resumeAndExperience?.resume
          ? cleanFilename(formValues?.resumeAndExperience?.resume.name)
          : null;

        try {
          if (formValues?.resumeAndExperience?.resume) {
            const resumeRef = createStorageRef(`/candidate/resume/${publicID}/${newFilename}`);
            await uploadBytes(resumeRef, formValues.resumeAndExperience.resume);
          }
        } catch (error: unknown) {
          setSectionSubmitButtonIsLoading(false);

          Sentry.captureException(error);
          toast({
            status: 'error',
            description: 'There was an error uploading your resume. Please try again!',
          });

          return;
        }

        await insertCandidate({
          variables: {
            objects: {
              country: formValues?.personalInfo?.location,
              country_id: formValues?.personalInfo?.country_id,
              linkedin_url: formValues?.resumeAndExperience?.linkedin,
              resume_filename: newFilename,
              email: formValues?.personalInfo?.email,
              first_name: formValues?.personalInfo?.firstName,
              last_name: formValues?.personalInfo?.lastName,
              availability: Candidate_Availability_Choices_Enum.InterestedAndAvailableNow,
              public_id: publicID,
              source: Candidate_Source_Choices_Enum.Applicant,
              applicant_jobs: {
                data: [
                  {
                    job: Number(jobID),
                    utm_source: queryString.get('utm_source'),
                    utm_medium: queryString.get('utm_medium'),
                    utm_campaign: queryString.get('utm_campaign'),
                    cid: queryString.get('cid'),
                    referring_url: isExternalReferrer ? document.referrer : null,
                  },
                ],
              },
              applicant_consents: {
                data: [{}],
              },
              candidate_skills: { data: candidate_skills || [] },
              candidate_curation_detail: {
                data: {
                  years_of_exp_range: formValues?.resumeAndExperience?.yoe,
                },
              },
            },
          },
        });
      }

      completeSection({
        section: Section.accountCreation,
        create_account_toggle: getMatchedToMoreRoles.getMatchedToMoreRoles,
      });
    } catch (error) {
      toast({
        status: 'error',
        description: String(error),
      });

      setSectionSubmitButtonIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      // We can only update the candidate when users don't need to validate their email (i.e.  when isAuthorized is set to true)
      auth.isAuthorized &&
      // This means the user has signed up successfully and candidateID is set
      auth.candidateID &&
      formValues.personalInfo &&
      formValues.resumeAndExperience &&
      isIdle_accountCreation
    ) {
      const handleAccountUpdate = async () => {
        dispatchAccountCreation({ type: 'pending' });

        // Casting formValues because typescript didn't recognize the if validation above which make it safe to read from this data
        const { personalInfo, resumeAndExperience } = formValues as {
          resumeAndExperience: ResumeAndExperienceForm;
          personalInfo: PersonalInfoForm;
        };
        const candidate_skills = resumeAndExperience.candidateSkills.map((newSkillValues) => ({
          candidate_id: auth.candidateID,
          competency: newSkillValues.competency,
          skill_id: newSkillValues.skillID,
          years_of_exp: newSkillValues.years_of_exp as InputMaybe<Scalars['Int']>,
          is_favorite: newSkillValues.is_favorite,
          source: Candidate_Source_Choices_Enum.Applicant,
        }));

        // Save the resume
        let newFilename = resumeAndExperience.resume
          ? cleanFilename(resumeAndExperience.resume.name)
          : null;
        let resumeUploadError;

        try {
          if (resumeAndExperience.resume) {
            const resumeRef = createStorageRef(
              `/candidate/resume/${auth.user?.candidate?.public_id}/${newFilename}`,
            );
            await uploadBytes(resumeRef, resumeAndExperience.resume);
          }
        } catch (error: unknown) {
          newFilename = null;
          resumeUploadError = error;

          Sentry.captureException(error);
        }

        try {
          await retryPromise(
            () =>
              updateCandidate({
                variables: {
                  candidate_id: auth.candidateID as number,
                  candidate_set_input: {
                    country: personalInfo.location,
                    country_id: personalInfo.country_id,
                    linkedin_url: resumeAndExperience.linkedin,
                    resume_filename: newFilename,
                    email: personalInfo.email,
                    first_name: personalInfo.firstName,
                    last_name: personalInfo.lastName,
                    availability: Candidate_Availability_Choices_Enum.InterestedAndAvailableNow,
                    source: Candidate_Source_Choices_Enum.Applicant,
                  },
                  job_id: Number(jobID),
                  // In the form, Years of Experience es required. So, here, it is safe to assume YoE is of type Candidate_Curation_Years_Of_Exp_Range_Choices_Enum
                  years_of_exp_range:
                    resumeAndExperience.yoe as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
                  candidate_skill_insert_input: candidate_skills,
                },
              }),
            {
              count: 4,
              delay: (_, retryCount) => interval(250 * retryCount).pipe(take(1)),
              resetOnSuccess: true,
            },
          );
        } catch (error) {
          dispatchAccountCreation({ type: 'rejected', error });
          setIsCreatingAccount(false);
          // After the submission was made it is safe to turn this off so the AppRouter can display the ActivityIndicator without affecting the logic of this component
          appState.flow.update('is-signing-up-in-direct-application', false);

          Sentry.captureException(error);
          toast({
            status: 'error',
            description:
              'There was an error updating your data. Please try updating it on the Profile page!',
          });

          return;
        }

        if (auth.candidateID && newFilename) {
          try {
            // This only ensures that the user will see the resume parsing loading bar when they land into home page even if the celery task
            // hasn't yet started. As this block is only important for UX, its places inside a try-catch to not block the user if it fails
            insert_candidate_parsed_resume({
              variables: {
                object: {
                  candidate_id: auth.candidateID,
                  // We only pass the candidate_id to use the default status of PENDING
                  // This will allow you to set status witout giving write permision to candidate to that table
                },
              },
            });
          } catch (error: unknown) {
            Sentry.captureException(error);
          }
        }

        dispatchAccountCreation({ type: 'resolved', data: null });

        // Displaying the error at the end to avoid unexpected user behavior
        if (resumeUploadError) {
          toast({
            status: 'error',
            description:
              'There was an error uploading your resume. Please try uploading it later in the profile page!',
          });
        }
      };

      handleAccountUpdate();
    }
  }, [
    auth.candidateID,
    auth.isAuthorized,
    formValues,
    formValues.personalInfo,
    formValues.resumeAndExperience,
    jobID,
    toast,
    updateCandidate,
    isIdle_accountCreation,
    auth.user?.candidate?.public_id,
    insert_candidate_parsed_resume,
  ]);

  useEffect(() => {
    track(name.directApplication.sectionViewed, {
      section_name: camelToSnakeCase(Section[activeSection]),
      step_num: activeSection + 1,
      step_total: 3,
    });
  }, [activeSection]);

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={`${path}/no-account-created-success`}
        onBeforeRedirect={() => {
          if (auth.isAuthorized) {
            appState.flow.update('landing-from-direct-application', 'create-account');
          }
        }}
        conditionalRedirects={[
          {
            condition: auth.isAuthorized,
            redirectURL: '/',
          },
          {
            condition: !formValues.personalInfo?.email,
            redirectURL: url,
          },
        ]}
      >
        <NoAccountCreatedSuccessController
          companyName={companyName}
          position={position}
          // When we get here, we know we have the email, otherwise a redirection would have been made
          firstName={formValues.personalInfo?.firstName as string}
          lastName={formValues.personalInfo?.lastName as string}
          email={formValues.personalInfo?.email as string}
        />
      </CustomRoute>

      <CustomRoute
        path={`${path}/account-created-success`}
        conditionalRedirects={[
          {
            condition: !auth.isAuthorized,
            redirectURL: url,
          },
        ]}
      >
        <AccountCreatedSuccessController companyName={companyName} position={position} />
      </CustomRoute>

      <CustomRoute
        path={path}
        conditionalRedirects={[
          {
            condition: auth.isAuthorized && !isCreatingAccount,
            redirectURL: '/',
          },
        ]}
      >
        <ApplicationSections
          yearsOfExperienceRangeChoices={yearsOfExperienceRangeChoices}
          completedSections={completedSections}
          sectionSubmitButtonIsLoading={
            sectionSubmitButtonIsLoading ||
            isUpdatingLoading ||
            isInsertingLoading ||
            isLoading_accountCreation
          }
          onAccountExistsClick={onAccountExistsClick}
          companyName={companyName}
          position={position}
          jobLocation={jobLocation}
          skillsOptions={skillsOptions}
          activeSection={activeSection}
          onSectionBoxEditClick={onSectionBoxEditClick}
          personalInfoOnComplete={personalInfoOnComplete}
          resumeAndExperienceOnComplete={resumeAndExperienceOnComplete}
          getMatchedToMoreRolesOnComplete={getMatchedToMoreRolesOnComplete}
        />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
